import FF from "../../components/forms/FormField.module.css";

import type { ReactNode } from "react";
import { useFormContext } from "react-hook-form";
import { ResourceCreateLinks } from "../../modules/api/resourceCreateLinks.ts";
import type { ResourceType } from "../../modules/api/resourceTypes.tsx";
import { billingStateAtom } from "../../modules/billing/billingLogic.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { NoticeBlock } from "../NoticeBlock.tsx";
import { WButton, WSubmitButton, type WSubmitButtonProps } from "../button/WButton.tsx";

export function FormActions({ children }: { children: ReactNode }) {
    return <div className={FF.FormActions}>{children}</div>;
}

export function FormSubmitButton(props: WSubmitButtonProps) {
    const {
        formState: { isLoading, isSubmitting },
    } = useFormContext();
    return <WSubmitButton isLoading={isLoading || isSubmitting} {...props} />;
}

interface CreateFormActionProps {
    resourceType: ResourceType;
}

/** A "Create" button that automatically sets the loading state from the form */
export function CreateFormAction({ resourceType }: CreateFormActionProps) {
    const state = useSuspenseQueryAtom(billingStateAtom);
    const { defaultAccount } = state;
    const isPending = defaultAccount?.status === "PENDING_VALIDATION";
    return (
        <FormActions>
            {state.canCreateResource(resourceType) ? (
                <FormSubmitButton>Create</FormSubmitButton>
            ) : (
                <div className={FF.Notice}>
                    <NoticeBlock
                        color="primary"
                        icon="jp-warning-icon"
                        button={
                            isPending ? null : (
                                <WButton color="primary" action={ResourceCreateLinks.billing_account}>
                                    Create Billing Account
                                </WButton>
                            )
                        }
                    >
                        {isPending
                            ? "Account details are being validated. You will be able to set up payment methods and use cloud services once the validation is complete."
                            : "An active billing account is needed to create resources."}
                    </NoticeBlock>
                </div>
            )}
        </FormActions>
    );
}
